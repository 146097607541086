import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private baseUrl = environment.baseUrl;
  private headers = new HttpHeaders({ 'Authorization': environment.apiKey });


  constructor(
    private http: HttpClient
  ) { }

  createReport(report) {
    return this.http.post<any>(`${this.baseUrl}/reportListing`, report, {
      headers: this.headers,
    }).toPromise()
  }

  getAllReports() {
    return this.http.get(`${this.baseUrl}/reportListing`, {
      headers: this.headers
    }).toPromise();
  }

}
