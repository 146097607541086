import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  private baseUrl = environment.baseUrl;
  private headers = new HttpHeaders({ 'Authorization': environment.apiKey });


  constructor(
    private http: HttpClient
  ) { }

  createFAQ(faq) {
    return this.http.post<any>(`${this.baseUrl}/faqs`, faq, {
      headers: this.headers,
    }).toPromise()
  }

  getAllFAQs() {
    return this.http.get(`${this.baseUrl}/faqs`, {
      headers: this.headers
    }).toPromise();
  }
}
