import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ReportModel } from '../models/category.model copy';
import { ReportService } from '../services/report/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.page.html',
  styleUrls: ['./report.page.scss'],
})
export class ReportPage implements OnInit {



  @Input() listingId : string;
  @Input() reporterId : string;
  report : ReportModel;

  loading = false;
  submitted = null;

  comment;

  constructor(
    public modalController: ModalController,
    private reportSrvc : ReportService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    //console.log(this.listingId);
    //console.log(this.reporterId);

    
    
    
  }

  dismiss() {
    this.modalController.dismiss();
  }

  submit(){
    this.loading = true;
    this.report = {
      listingId : this.listingId,
      reporterId : this.reporterId,
      comment :{
        createdBy : 'REPORTER',
        description : this.comment
      }
    }

    //console.log(this.report);
    this.reportSrvc.createReport(this.report).then(
      resp =>{
        //console.log(resp);
        this.loading = false;
        this.submitted = true;
        this.dismiss();
        this.presentToast("Item reported successfully")
      }
    ).catch(error => {console.log(error); this.loading = false;})

  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

}
